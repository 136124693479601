import { Container, Grid } from '@material-ui/core';
import * as React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

export default function SlidShowCard({images, height=200, width=200}) {
  if(images){
    return (
      <div style={{backgroundColor: '#cce6ff'}}>
          <AliceCarousel autoPlay autoPlayInterval="3000" disableButtonsControls={true} infinite={true} >
                <Container maxWidth={true}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <img src={images[0]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[0]?.water_body_code || images[0]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[1]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[1]?.water_body_code || images[1]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[2]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[2]?.water_body_code || images[2]?.name}</div>
                    </Grid>
                  </Grid>
                </Container>

                <Container maxWidth={true}>
                  <Grid container spacing={5}>
                    <Grid item xs={4}>
                      <img src={images[3]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[3]?.water_body_code || images[3]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[4]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[4]?.water_body_code || images[4]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[5]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[5]?.water_body_code || images[5]?.name}</div>
                    </Grid>
                  </Grid>
                </Container>

                <Container maxWidth={true}>
                  <Grid container spacing={5}>
                    <Grid item xs={4}>
                      <img src={images[6]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[6]?.water_body_code || images[6]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[7]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[7]?.water_body_code || images[7]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[8]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[8]?.water_body_code || images[8]?.name}</div>
                    </Grid>
                  </Grid>
                </Container>

                <Container maxWidth={true}>
                  <Grid container spacing={5}>
                    <Grid item xs={4}>
                      <img src={images[9]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[9]?.water_body_code || images[9]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[10]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[10]?.water_body_code || images[10]?.name}</div>
                    </Grid>
  
                    <Grid item xs={4}>
                      <img src={images[11]?.image_url} alt="" style={{ height: "500px", maxWidth: "100%"}}/> 
                      <div>{images[11]?.water_body_code || images[11]?.name}</div>
                    </Grid>
                  </Grid>
                </Container>
  
                {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      <img src={images[9]?.image_url} alt="" style={{ height: "500px", width: "400px"}} /> 
                      <div>{images[9]?.name}</div>
                    </div>
  
                    <div>
                      <img src={images[10]?.image_url} alt="" style={{ height: "500px", width: "400px"}} /> 
                      <div>{images[10]?.name}</div>
                    </div>
  
                    <div>
                      <img src={images[11]?.image_url} alt="" style={{ height: "500px", width: "400px"}} /> 
                      <div>{images[11]?.name}</div>
                    </div>
                </div> */}
  
              {/* {[image1, image1, image1].map(i => <img src={i} style={{width: "100%", height: "500px", objectFit: "cover"}} alt=""/>)} */}
          </AliceCarousel>
      </div>
    );
  } else{
    return <div />
  }
  
}